<template>
  <div class="fan-group-report">
    <template v-if="firstInitFinished">
      <apply :report="report" @reInit="reInit" :auth="auth" :confirm="confirm" :close="close" @reset="reset"/>
      <van-cell-group inset :border="false" v-if="applicationDetailValue.process">
        <div style="margin-left: 0.35rem;font-size: 17px;font-weight: 500;margin-top: 0.35rem;margin-bottom: 0.25rem">
          流程
        </div>
        <process :list="operates" :application-value="applicationDetailValue" style="margin-left: 0.15rem"/>
      </van-cell-group>
    </template>
    <template v-else>
      <div
          style="position: fixed;top: 0;left: 0;right: 0;bottom: 0;display: flex;justify-content: center;align-items: center">
        <van-loading/>
      </div>
    </template>
  </div>
</template>

<script>
import Apply from "@/components/Apply/index";
import Process from "@/components/Apply/Process";
import {saveApprovalDetail, getApprovalDetail} from "@/api/hoss";
import {Loading} from "vant";

export default {
  components: {Apply, Process},
  data() {
    return {
      report: {},
      operates: [],
      applicationDetailValue: {},
      auth: true,
      firstInitFinished: false,
    };
  },
  created() {
    this.init();
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.$nextTick(() => {
      this.init();
    });
  },
  beforeRouteEnter(to, from, next) {
    const {applicationType, applicationId} = to.query;
    var title = {
      3: "终止审核",
      2: "结单审核",
      1: "转二阶段审核",
    }[applicationType];
    if (!title) {
      title = "审核详情";
    }
    // 申请页应当叫做 例如：转二阶段申请
    if (!applicationId) {
      title = title.replace("审核", "申请");
    }
    to.meta.title = title;
    next();
  },
  methods: {
    async init() {
      const {clientId, applicationId = "", applicationType} = this.$route.query;
      this.report = {
        reportTime: +new Date(),
        detail: "",
        clientId,
        approvalResult: 0,
        applicationType,
        applicationId
      };
      if (applicationType === '1') {
        // this.report.scheduledDate = +new Date() 明天
        this.report.scheduledDate = +new Date() + 24 * 60 * 60 * 1000;
      }
      this.operates = [];
      this.applicationDetailValue = {};
      this.auth = true;
      if (applicationId) {
        const {value} = await getApprovalDetail(applicationId);
        console.log(value);
        this.report = {
          ...this.report,
          ...value,
        };
        this.operates = value.process;
        this.applicationDetailValue = value;
        this.auth = value.authToApprove ?? true;
      }
      this.firstInitFinished = true;
    },
    async confirm(report) {
      await saveApprovalDetail(report);
      this.$router.back();
    },
    async reInit() {
      this.firstInitFinished = false;
      await this.init();
    },
    close() {
      this.$router.back();
    },
    reset() {
      const {clientId, applicationType: applicationType} = this.$route.query;
      this.$router.replace(`/hoss/application?clientId=${clientId}&applicationType=${applicationType}`);
    },
  },
};
</script>

<style lang="less" scoped>
.fan-group-report {
  padding-bottom: 44px;
}

.van-cell-group {
  margin: 10px;
  border-radius: 10px;
}
</style>
